<template>
  <el-container>
    <el-header>
      <head-wrapper class="header">
        <template #text>
          <span id="title-text">食品安全信息监管平台</span>
        </template>
        <template #time>
          <span id="time">{{time}} {{weather.wea}} {{weather.tem_night}}-{{weather.tem_day}}°C</span>
        </template>
        <template #navbar>
         <!-- <el-icon><Avatar /></el-icon> -->
          <el-popover trigger="click" :width="300"
            popper-style="background-color:#071f63;color:#f2f2f2; box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
            <template #reference>
             <!-- <el-avatar :icon="UserFilled" /> -->
             <!-- <el-icon><UserFilled /></el-icon> -->
             <el-icon style="--color:#f2f2f2"><User /></el-icon>
             <!-- <el-icon><Avatar /></el-icon> -->
              <!-- <el-avatar style="--el-avatar-bg-color:#598ec2;"> -->
                <!-- <span style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{userInfo.name}}</span> -->
                <!-- </el-avatar> -->
            </template>
            <template #default>
              <div
                style="display:flex;flex-direction: column;align-items: center;justify-items: flex-start; width:100%;backgroud-color:#00023A">
                <el-descriptions style="width:100%;color:#f2f2f2;--el-text-color-primary:#f2f2f2;--el-fill-color-blank:#071f63;--el-text-color-regular:#f2f2f2" column="1" title="用户信息">
                  <el-descriptions-item label="用户ID">{{userName}}</el-descriptions-item>
                  <el-descriptions-item label="单位">{{userInfo.name}}</el-descriptions-item>
                  <template #extra>
                    <el-button style="--el-button-bg-color:transparent;" @click="logOut()"><span style= "color:#f2f2f2;font-weight:bold;font-size:1.5vh">退出登录</span>
                    </el-button>
                  </template>
                </el-descriptions>
              </div>
            </template>
          </el-popover>
        </template>
      </head-wrapper>
    </el-header>
    <el-main>
      <div class="side-wrapper" :style="{ opacity: Active ? 1 : 0 }"
          @mouseenter="Active = true"
          @mouseleave="Active = false">
        <!-- <div class="collapse-button" @click="isCollapse = !isCollapse">
          <span v-if="isCollapse">&gt;</span>
          <span v-if="!isCollapse">&lt;</span>
        </div> -->
        <el-menu default-active="index/home" active-text-color="#FFA500" text-color="white" class="el-menu-vertical"  router >
          <el-menu-item index="home">
            <el-icon class="tabs">
              <compass />
            </el-icon>
            <template #title><span class="tabs">首页</span></template>
          </el-menu-item>
          <el-sub-menu index="search2">
            <template #title>
              <el-icon class="tabs">
                <search />
              </el-icon>
              <span class="tabs">查询</span>
            </template>
            <el-menu-item index="search">
              <template #title><span class="tabs">快检查询</span></template>
            </el-menu-item>
            <el-menu-item v-if="(this.userRole!=='org')" index="region">
              <template #title>
                <span class="tabs">区域查询</span>
              </template>
            </el-menu-item>
            <el-menu-item index="audit">
              <template #title><span class="tabs">不合格查询</span></template>
            </el-menu-item>
            <el-menu-item index="upload" v-if="this.userRole==='org'">
              <el-icon class="tabs">
                <upload-fill />
              </el-icon>
              <template #title><span class="tabs">数据上传</span></template>
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item index="screen" v-if="this.userRole==='org'">
            <el-icon class="tabs">
              <full-screen />
            </el-icon>
            <template #title><span class="tabs">大屏展示</span></template>
          </el-menu-item>
          <el-menu-item index="report" v-if="(this.userRole==='org')">
            <el-icon class="tabs">
              <list />
            </el-icon>
            <template #title><span class="tabs">报表</span></template>
          </el-menu-item>
          <el-menu-item index="cityamount" v-if="(this.userRole==='gov')">
            <el-icon class="tabs">
              <list />
            </el-icon>
            <template #title><span class="tabs">地区报表</span></template>
          </el-menu-item>
          <el-sub-menu index="setting" v-if="this.userRole==='admin'">
            <template #title>
              <el-icon class="tabs">
                <setting />
              </el-icon>
              <span class="tabs">设置</span>
            </template>
            <el-menu-item index="addorg">
              <template #title>
                <span class="tabs">检测机构注册</span>
              </template>
            </el-menu-item>
            <el-menu-item index="uploader">
              <template #title>
                <span class="tabs">上传绑定</span>
              </template>
            </el-menu-item>
            <el-menu-item index="grant">
              <template #title>
                <span class="tabs">账号授权</span>
              </template>
            </el-menu-item>
            <!-- <el-menu-item v-if="this.userRole==='admin'" index="userManage">
              <template #title>
                <span class="tabs">账号管理</span>
              </template>
            </el-menu-item> -->
          </el-sub-menu>
        </el-menu>
      </div>
      <div :style="{'margin-left':Active ? '8vw':0}" class="main-wrapper">
        <!-- <div class="tags">
          <router-link to="home">
            <home-filled style="width: 4vh; height: 4vh;" :class="isActive('/index/home')?'active':''" />
          </router-link>
          <router-link v-for="tab in Array.from(this.tabs)" :to="tab.path" :key="tab.path">
            <div class="tag" :class="isActive(tab.path)?'active':''">
              <span>{{tab.title}}</span>
              <span @click.prevent.stop="closeTab(tab)">
                <el-icon style="height:4vh;width:4vh;">
                  <close />
                </el-icon>
              </span>
            </div>
          </router-link>
        </div> -->
        <div class="sub-content">
          <router-view #default="{Component,route}">
            <keep-alive>
              <component :is="Component" :key="route.path" v-if="$route.meta.keepAlive"></component>
            </keep-alive>
            <component :is="Component" :key="route.path" v-if="!$route.meta.keepAlive"></component>
          </router-view>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import HeadWrapper from "../components/Head.vue";
import {
  Location,
  Search,
  Menu as IconMenu,
  List,
  Compass,
  UserFilled,
  Avatar,
  Setting,
  HomeFilled,
  Close,
  FullScreen,
  User
} from "@element-plus/icons-vue";
import {getCurrentTime} from 'utils/utils'
import {getWeather} from 'network/tianqi'

const user = {}
export default {
  components: {
    HeadWrapper,
    Location,
    Search,
    IconMenu,
    List,
    Compass,
    UserFilled,
    Avatar,
    Setting,
    HomeFilled,
    Close,
    FullScreen,
    User
  },
  watch: {
    $route(to, from) {
      if (to.name !== '首页' && to.name !== '登录')
        this.addTab()
    }
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole
    },
    userName() {
      return this.$store.getters.getUserName
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },

    tabs() {
      return this.$store.getters['tabsRoutes/tabRoutes']
    }
  },
  data() {
    return {
      time:'',
      weather:{},
      Active:false
    };
  },
  created() {
    this.prepare()
    this.getTime()
  },
  methods: {

    prepare() {
    },

    logOut() {
      localStorage.removeItem("currentLogin");
      let promise = [
        this.$store.dispatch('tabsRoutes/clearTab'),
        this.$store.dispatch('companies/clear'),
        this.$store.dispatch('areas/clear')
      ]
      Promise.all(promise).then(value => {
        this.$router.replace({ name: "登录" })
      },
        reason => {
          console.log(reason.message)
        }
      )
    }
    ,
    addTab() {
      if (this.$route.name) {
        const route = this.$route
        this.$store.dispatch('tabsRoutes/addTab', route)
      }
    },
    closeTab(route) {
      this.$store.dispatch('tabsRoutes/tabClose', route).then(res => {
        if (this.isActive(route.path)) {
          let lastRoute = res.slice(-1)[0]
          if (lastRoute && (!lastRoute.path.startsWith('/index')))
            this.$router.push(lastRoute)
          else
            this.$router.push('home')
        }
      })
    },
    isActive(path) {

      return path === this.$route.path
    },
    getTime (){
      this.time = getCurrentTime()
      setInterval(()=>{
        this.time = getCurrentTime()
      },30000)

      //天气接口
      const weather = new Promise((resolve,reject)=>{
        getWeather(resolve,reject)
      }) 
      weather.then(res=>{
        console.log(res );
        this.weather = res
      })
      
    }
  },
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100vw;
  height: 100vh;
  background:#071f63
}

.el-header {
  height: 10vh;
  border-bottom: 2px solid var(--el-border-color-base);
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding: 0;

  #title-text {
    font-size: 4vh;
    text-align: center;
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 2px gray;
  }

  .el-icon {
    height: 9vh;
    width: 10vh;

    svg {
      width: 60%;
      height: 60%;
    }
  }
}

.el-main {
  height: 90vh;
  padding: 0;
  flex-direction: row;

.el-menu {
  background-color: transparent;
  border-right: none;
}

  .side-wrapper {
    height: 90vh;
    width: 8vw;
    position: absolute;
    left: 0;
    // background-color: white;
    background: url('../assets/pictures/side-bg.png') no-repeat;
    z-index: 9;
    overflow: visible;
    .el-menu {

    // background-color: transparent;
    --el-menu-item-height: 7vh;
      
    }

    .el-menu-vertical {
      width: 100%;
      height: 100%;
     /deep/ .el-menu {

      background-color: transparent;
      }
    }

    .tabs {
      font-size: 2vh;
      // color: white;
       text-align: justify;

      svg {
        height: 2vh;
        width: 2vh;
      }
    }

    .el-icon {
      height: 2vh;
      width: 2vh;
    }

    .el-menu-item {
      width: 100%;
      min-width: auto;
    }

    .el-sub-menu {
      width: 100%;

      .el-menu-item {
        height: 5vh;
        line-height: 5vh;
      }
    }

    //   .collapse-button {
    //     width: 16px;
    //     height: 32px;
    //     position: absolute;
    //     top: 50%;
    //     right: 0px;
    //     z-index: 10;
    //     line-height: 32px;
    //     font-size: 28px;
    //     color: gray;
    //     background-color: white;
    //     border: 1px solid var(--el-border-color-base);
    //     border-top-left-radius: 12px;
    //     border-bottom-left-radius: 12px;
    //   }
  }

  .main-wrapper {
    flex: 0 0;
    display: flex;
    flex-direction: column;

    //overflow: hidden;
    .tags {
      width: calc(100% - 20px);
      height: 4vh;
      color: ghostwhite;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;

      .active {
        color: rgb(64, 158, 252) !important;
      }

      a {
        text-decoration-line: none !important;
      }

      .tag {
        height: 100%;
        margin-left: 5px;
        margin-right: 5px;
        width: 7vw;
        line-height: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 3px;
        color: gray;
      }

      .icon {
        color: gray;
      }
    }

    .sub-content {
      display: flex;
      width: 100%;
      height: 82vh;
      flex: 1 1 82vh;
      justify-content: center;
      // background-color: #f5f5f5;
    }
  }
}
.description  {
  background-color: #00023A;
}
</style>