const today = ()=>{
    var today = new Date();

var year = today.getFullYear();

// 月份是从 0 开始计数的，所以要加 1
var month = (today.getMonth() + 1).toString().padStart(2, '0');

var day = today.getDate().toString().padStart(2, '0');

var formattedDate = year + '-' + month + '-' + day;
return formattedDate
}

const getWeather = (resolve,reject)=>{

    //避免重复获取天气数据
    const oldData = JSON.parse(localStorage.getItem('WEATHER')) || {}
    const now = today()
    if(oldData.date == now){
        resolve (oldData)
        return
    }

    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
  if (xhr.readyState === XMLHttpRequest.DONE) {
    if (xhr.status === 200) {
        const res =  xhr.responseText
      console.log('Received data:', xhr.responseText);
      const date = res.date
      localStorage.setItem('WEATHER', res)
      resolve (JSON.parse (res))
    } else {
      console.error('Error fetching data. Status:', xhr.status);
      
    }
  }
};
xhr.open('GET', 'http://v1.yiketianqi.com/free/day?appid=25188127&appsecret=2si0YSKw&unescape=1');
xhr.send();
    
   
}

export {getWeather}