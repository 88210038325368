<template>
  <div class="head-wrapper">
    <!-- <div id="icon">
        <slot name="icon"></slot>
    </div> -->
    <div id='text'>
        <slot name="text"></slot>
    </div>
    <div id='time'>
      <slot name="time"></slot>
    </div>
    <div id='navBarArea'>
        <slot name="navbar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadWrapper",
};
</script>
<style lang="less">
.head-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // background: linear-gradient(to right,#0b144b 0%,#0a3aba 100%);
  background-image: url('../assets/pictures/head_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
 #icon{
    height:100%;
    align-items:center;
    width: 100px;
    flex: 0 0 100px;
 }
 #text{
    position: absolute;
    width: 100%;
     height:100%;
     display: flex;
     justify-content: center;
     align-items: center;
     white-space: nowrap;
     text-align: start;
    // background-image: url('../assets/pictures/head-left.png');
    // background-size: cover;

 }
 #time {
    height:100%;
    //  flex:1 1 35%;
     align-content: center;
     white-space: nowrap;
     text-align: center;
     line-height: 10vh;
     color: white;
 }
  #navbarArea {
    height:9vh;
    width:15vh;
    color: #f2f2f2;
    // flex: 1 1 40%;
    display: flex;
    justify-content: flex-end !important;
    align-items: flex-start;
  }
}
</style>